<template>
  <vx-card class="internal-funds-transfer-document main-box top-zero-radius md:w-1/2 p-2">
    <div class="sources">
      <div class="label">{{ $t('treasury.internalFundsTransfer.document.labels.payer') }}</div>
      <ul>
        <li v-if="sources">
          <div class="row-number">1</div>
          <div class="name">{{ sources.name || '' }}</div>
          <div v-if="finalPrice !== 0" class="price" :class="{'text-danger': finalPrice < 0, 'text-success': finalPrice >= 0}">{{ addComma(Math.abs(finalPrice)) }} {{ $locale.currency() }}</div>
        </li>
      </ul>
    </div>

    <div class="destinations mt-4">
      <div class="label">{{ $t('treasury.internalFundsTransfer.document.labels.destinations') }}</div>
      <ul>
        <li v-if="destinations">
          <div class="row-number">1</div>
          <div class="name">{{ destinations.name }}</div>
          <div v-if="price !== 0" class="price" :class="{'text-danger': price < 0, 'text-success': price >= 0}">{{ addComma(price) }} {{ $locale.currency() }}</div>
        </li>
      </ul>
    </div>

    <div class="details mt-4">
      <div class="label">{{ $t('treasury.internalFundsTransfer.document.labels.details') }}</div>
      <ul>
        <li v-for="(detail, detail_index) in details" :key="detail_index">
          <div class="row-number">{{ detail_index + 1 }}</div>
          <div class="key">{{ detail.key }}</div>
          <div class="value" :class="detail.classes">{{ detail.value }}</div>
        </li>
      </ul>
    </div>

    <custom-dialog ref="deleteDocumentConfirmation"
                   :title="`${$t('treasury.internalFundsTransfer.document.confirmations.delete.title')}`"
                   :body="`${$t('treasury.internalFundsTransfer.document.confirmations.delete.body')}`"
                   @accept="deleteDocument"/>

    <button v-show="false" id="DeleteDocumentBTN" @click="$refs.deleteDocumentConfirmation.showDialog()"/>
  </vx-card>
</template>

<script>
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {deleteInternalFundsTransfer, getInternalFundsTransfer} from '../../../../../http/requests/treasury/internalFund'
import {addComma} from '../../../../../assets/js/functions'

export default {
  name: 'internalFundsTransferDocument',
  components: {CustomDialog},
  metaInfo () {
    return {
      title: this.$route.params.id ? this.$t('treasury.internalFundsTransfer.document.dynamicTitle', {docNumber: this.$route.params.id}) : this.$t('treasury.internalFundsTransfer.document.title')
    }
  },
  data () {
    return {
      sourceTypesMapper: {
        'User': this.$t('treasury.internalFundsTransfer.types.user'),
        'Bank': this.$t('treasury.internalFundsTransfer.types.bank'),
        'Cash': this.$t('treasury.internalFundsTransfer.types.cash'),
        'Storeroom': this.$t('treasury.internalFundsTransfer.types.storeroom'),
        'PaymentGateway': this.$t('treasury.internalFundsTransfer.types.paymentGateway')
      },
      paymentTypes: {
        ctc: this.$t('treasury.internalFundsTransfer.bankTypes.card'),
        iban: this.$t('treasury.internalFundsTransfer.bankTypes.sheba')
      },
      destinations: {},
      sources: {},
      details: [],
      price: 0,
      finalPrice: 0,
      actions: {
        leftToolbar: [
          {
            id: 'DeleteDocumentBTN',
            permission: 'internal_fund.delete',
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral'
          },
          {
            id: {name: 'internalFundsTransfersActivitiesLog', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'LAST_ACTIVITIES',
            iconPack: 'useral',
            permission: 'activity_logs.show'
          },
          {
            id: {name: 'print-internal-funds-transfer-page', params: {id: this.$route.params.id}},
            type: 'new-page-link',
            icon: 'PRINT',
            iconPack: 'useral'
          },
          {
            id: 'routeBackBTN',
            route: {name: 'treasuryInternalFundsTransfers'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      this.$store.dispatch('setPageTitle', this.$route.params.id ? this.$t('treasury.internalFundsTransfer.document.dynamicTitle', {docNumber: this.$route.params.id}) : this.$t('treasury.internalFundsTransfer.document.title'))
    })

    this.getDocument()
  },
  methods: {
    getDocument () {
      getInternalFundsTransfer(this.$route.params.id).then(response => {
        const document = response.data.data
        this.destinations = document.payee
        this.sources = document.payer
        this.price = document.price
        this.finalPrice = document.final_price || document.price

        this.details.push({
          key: this.$t('treasury.internalFundsTransfer.document.labels.creator'),
          value: document.creator.name
        })
        this.details.push({
          key: this.$t('treasury.internalFundsTransfer.document.labels.date'),
          value: document.date,
          classes: 'rtl'
        })
        if (document.payment_type) {
          this.details.push({
            key: this.$t('treasury.internalFundsTransfer.document.labels.paymentType'),
            value: this.paymentTypes[document.payment_type]
          })
        }
        if (document.wage) {
          this.details.push({
            key: this.$t('treasury.internalFundsTransfer.document.labels.wage'),
            value: `${addComma(document.wage)} ${this.$locale.currency()}`,
            classes: 'text-danger ltr'
          })
        }
        this.details.push({
          key: this.$t('treasury.internalFundsTransfer.document.labels.description'),
          value: document.description ? document.description : this.generateDocumentDescription({type: 'internal_fund_transfer', reference: document}, {hasRoute: false})
        })
      })
    },
    addComma (val) {
      return addComma(val)
    },
    deleteDocument () {
      deleteInternalFundsTransfer(this.$route.params.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.internalFundsTransfer.document.notifications.delete.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
        this.$router.replace({name: 'treasuryInternalFundsTransfers'})
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.document.notifications.delete.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.internal-funds-transfer-document {

  .sources, .destinations, .details {

    .label {
      font-size: 14px;
      font-weight: 600;
      line-height: 50px;
      border-bottom: 1px solid #cecece;
    }

    ul {

      li {
        font-size: 13px;
        line-height: 35px;
        background: #cecece40;
        display: flex;
        flex-direction: row;

        .row-number {
          min-width: 50px;
          text-align: center;
        }

        .name {
          flex: 3;
        }

        .price {
          flex: 1;
          text-align: center;
        }

        .key {
          flex: 1;
          text-align: center;
        }

        .value {
          flex: 1;
          text-align: center;
        }

        &:nth-child(2n) {
          background: #cecece10;
        }
      }
    }
  }
}
</style>
